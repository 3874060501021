function makeProductBoxSameHeight() {
	if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
		var highestBox = 0;
		var elementArr = [".name"];

		$.each( elementArr, function( key, value ) {
			highestBox = 0;
			$('.product-grid-item ' + value).height('auto');

			$('.product-grid-item ' + value).each(function() {
				if ($(this).height() > highestBox) {
					highestBox = $(this).height();
				}
			});

			$('.product-grid-item ' + value).height(highestBox);
		});
	}
}

function makePostBoxSameHeight() {
	if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
		var highestBox = 0;
		var elementArr = [".name", ".summary"];

		$.each( elementArr, function( key, value ) {
			highestBox = 0;
			$('.list_post .post ' + value).height('auto');

			$('.list_post .post ' + value).each(function() {
				if ($(this).height() > highestBox) {
					highestBox = $(this).height();
				}
			});

			$('.list_post .post ' + value).height(highestBox);
		});
	}
}

$(document).ready(function($) {

	makeProductBoxSameHeight();
	makePostBoxSameHeight();

	$(window).on('resize', function(){
		makeProductBoxSameHeight();
		makePostBoxSameHeight();
	});

	// Điều khiển row trên bảng có thể click như link, sử dụng thuộc tính data-href
	$(".clickable-row").on('click', function() {
			window.location = $(this).data("href");
	});
});
